<script setup>
const props = defineProps({
  car: Object,
  dictionary: Map,
  control: Boolean,
})

const pins = defineModel('pins', {default: () => []})
const pin = (id, checked) => {
  pins.value.addOrRemove(id, checked);
}

</script>

<template>
  <template v-for="[id,name] of props.dictionary">
      <div class="h-8 pt-2 z-20">
        <div v-if="control"
             class="absolute auto-margin ml-1 md:ml-5 z-0 font-extralight text-xs"
             @change="(checked) => pin(id, checked)"
        >{{ name }}
        </div>
      </div>
      <div
        :title="name"
        class="rounded h-9 bg-gray-100 flex justify-center items-center"
      >
        <IconCheckGreen v-if="props.car.equipment.find(i => i == id)" />
        <IconMinusGrey v-else />
      </div>
  </template>
</template>
